import type PinMarker from '@/ttmap/pin-marker'
import type { Filter, Location, Aggregation, Review, Parking } from '@/location'
import { useMapsStore } from '@/store/maps'
import { useProfileStore } from './profile'
import type { RequestError } from '@/types'

import { defineStore } from 'pinia'
import axios from '@/axios'
import type { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson'

export enum AbortReason {
  Cancelled = 'cancelled'
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL
})

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

const abortControllers = new Map<string, AbortController>()

export const useLocationStore = defineStore({
  id: 'location',
  state: () => ({
    location: null as Location | null,
    reviews: [] as Review[],
    parkings: [] as Parking[],
    open: false,
    lastId: null as string | null,
    tempAreaFeatureCollection: null as FeatureCollection<Geometry, GeoJsonProperties> | null,
    filter: {
      properties: [],
      meta: [],
      ranges: []
    } as Filter,
    aggregation: {
      total: 0,
      aggregates: [] as Aggregation[]
    },
    isFetching: false,
    pin: null as PinMarker | null
  }),
  getters: {
    locationSource(state): string {
      return (
        import.meta.env.VITE_GATEWAY_URL +
        '/location/list?type=geojson' +
        '&filter=' +
        JSON.stringify(state.filter) +
        '&v=' + this.lastId
      )
    }
  },
  actions: {
    toggle(): void {
      this.open = !this.open
      if (!this.open) {
        this.tempAreaFeatureCollection = null
        useMapsStore().map?.removeLocationAreaFeatures()
      }
    },
    close(): void {
      this.open = false
      this.tempAreaFeatureCollection = null
      useMapsStore().map?.removeLocationAreaFeatures()
    },
    async fetchReviews(): Promise<RequestError | Review[]> {
      if (!this.location?.id) {
        return []
      }
      try {
        const response = await instance.get(`/review/${this.location.id}/list`)
        if (response.status === 200) {
          if (response?.data) {
            this.reviews = response.data
          } else {
            this.reviews = []
          }
          return this.reviews
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async addReview(data: any): Promise<RequestError | void> {
      if (!this.location?.id) {
        return
      }
      try {
        const formData = new FormData()
        formData.append('comment', data.comment)
        formData.append('rating', data.rating)
        data.images.forEach((image: File) => {
          if (image?.type.match(/^image\//)) {
            formData.append('images[]', image)
          }
        });

        const response = await instance.post(
          `/review/${this.location.id}/update`,
          formData
        )
        if (response.status === 200) {
          this.location.rating = response.data.rating
          this.location.review_count = response.data.review_count
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async removeReview(id: string): Promise<RequestError | void> {
      if (!this.location?.id) {
        return
      }
      try {
        const response = await instance.delete(`/review/delete/${id}`)
        if (response.status === 200) {
          // TODO: Fetch reviews again and correct rating and review_count
          this.fetchReviews()
      }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async bookParking(data: any): Promise<RequestError | void> {
      if (!this.location?.id) {
        return
      }
      if (useProfileStore().currentVehicle?.reg_number) {
        data.reg_number = useProfileStore().currentVehicle?.reg_number
      }
      try {
        const response = await instance.post(`/location/parking`, data)
        if (response.status === 200) {
          return
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async newLocation(): Promise<void> {
      useMapsStore().map?.addSelectedLocationAreaFeatures()
      let name = ''
      if (this.pin && this.pin.lngLat) {
        const reverse = await useMapsStore().reverse({
          coordinates: [this.pin.lngLat.lng, this.pin.lngLat.lat]
        })
        if (reverse && reverse.length > 0) {
          // console.log('Reverse', reverse)
          name = reverse[0].place_name
        }
      }
      this.location = {
        id: '',
        name,
        address: {
          street: '',
          city: '',
          zip: '',
          country: ''
        },
        coordinates: {
          lat: this.pin?.lngLat?.lat || 0,
          lng: this.pin?.lngLat?.lng || 0
        },
        meta: []
      }
      this.parkings = []

      this.open = true
    },
    async setLocation(locationId?: string): Promise<RequestError | void> {
      this.location = null

      if (!locationId) {
        this.newLocation()
        return
      }

      try {
        const response = await instance.get(`/location/${locationId}`)
        if (response.status === 200) {
          this.location = response.data as Location
          useMapsStore().map?.addSelectedLocationAreaFeatures()
          this.fetchReviews()
          this.parkings = response.data.parkings ?? []
          return response.data
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async updateLocation(locationData: Location, id?: string): Promise<any> {
      locationData.parkings = this.parkings ?? []
      try {
        const response = await instance.post(
          '/location' + (id ? `/update/${id}` : '/update'),
          locationData
        )
        if (response.status === 200) {
          this.lastId = response.data.id
          this.setLocation(response.data.id)
          return response.data
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async updateCurrentLocation(): Promise<RequestError | void> {
      // Add currently selected location to the database
      // TODO: Actually use the current location and not just the current pin
      // console.log('Add location', this.pin?.lngLat?.lng, this.pin?.lngLat?.lat)
      const coordinates = {
        lat: this.pin?.lngLat?.lat,
        lng: this.pin?.lngLat?.lng
      }
      if (!coordinates.lat || !coordinates.lng) {
        console.error('No coordinates given for new location', coordinates)
        return
      }

      const comforts = [
        {
          type: 'comfort',
          value: 'wifi'
        },
        {
          type: 'comfort',
          value: 'toilet'
        }
      ]

      await this.updateLocation({
        name: 'Test',
        city: 'Testcity',
        address: {
          street: 'Teststreet' + Math.floor(Math.random() * 100),
          city: 'Testcity',
          zip: '12345',
          country: 'Testcountry'
        },
        coordinates: {
          lat: this.pin?.lngLat?.lat,
          lng: this.pin?.lngLat?.lng
        },
        meta: [comforts[Math.floor(Math.random() * comforts.length)]]
      } as Location)

      this.pin?.remove() // remove the pin
    },
    async updateAggregations() {
      this.isFetching = true
      try {
        abortControllers.get('/location/list')?.abort(AbortReason.Cancelled)
        abortControllers.set('/location/list', new AbortController())
        const response = await instance.get('/location/list', {
          signal: abortControllers.get('/location/list')?.signal,
          params: {
            type: 'aggregate',
            filter: JSON.stringify(this.filter),
            v: this.lastId
          }
        })
        if (response.status === 200) {
          this.isFetching = false
          this.aggregation.total = response.data?.total || 0
          const aggregates = (response.data?.aggregates ||
            []) as Aggregation[]
          this.aggregation.aggregates = aggregates.map<Aggregation>((agg: Aggregation) => {
            if (!agg.count) {
              agg.count = 0
            }

            return agg
          })
          return response.data
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        this.isFetching = false
        if (error instanceof DOMException) {
          return false // Should be a request cancellation
        }
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async removeLocation(id: string): Promise<any> {
      try {
        const response = await instance.delete(`/location/${id}`)
        if (response.status === 200) {
          this.location = null
          this.lastId = 'deleted-'+id
          useMapsStore().map?.locations?.refresh()
          return { success: true }
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async removeParking(id: string): Promise<any> {
      try {
        const response = await instance.delete(`/location/parking/${id}`)
        if (response.status === 200) {
          this.parkings = this.parkings.filter((p) => p.id !== id)
          return { success: true }
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    flyToLocation(location: Location, zoom: number) {
      useMapsStore().map?.flyTo({
        center: [location.coordinates.lng, location.coordinates.lat],
        zoom
      })
    },
    async fetchParkingBookings(id: string): Promise<any> {
      try {
        const response = await instance.get(`/location/parking/${id}/bookings`)
        if (response.status === 200) {
          return response.data?.bookings || []
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async contactJour(id: string, phoneNumber: string): Promise<any> {
      try {
        const response = await instance.post(`/location/parking/${id}/contact-jour`, { phoneNumber })
        if (response.status === 200) {
          return response.data
        }
        return { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    }
  }
})
